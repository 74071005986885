import { LazyMotion } from 'framer-motion';
import dynamic from 'next/dynamic';

import { FavoritesProvider } from '../favorites-context/favorites-context';
import { GlobalProvider } from '../global-context/global-context';

const MarketPickerProvider = dynamic(() =>
  import('@hultafors/shared/context').then(
    (module) => module.MarketPickerProvider,
  ),
);

const SWRProvider = dynamic(() =>
  import('@hultafors/shared/context').then((module) => module.SWRProvider),
);

const lazyMotionFeatures = () =>
  import('./lazy-motion-features').then((res) => res.default);

interface AppProviderProps {
  children?: React.ReactNode;
  value: any;
}
export const AppProvider: React.FC<AppProviderProps> = ({
  value,
  children,
}) => {
  const active = [value.settings?.hostname, value.settings?.language?.urlPrefix]
    .filter(Boolean)
    .join('/');
  return (
    <SWRProvider>
      <LazyMotion features={lazyMotionFeatures}>
        <GlobalProvider value={value}>
          <FavoritesProvider settings={value.settings}>
            <MarketPickerProvider
              label={value?.global?.changeLanguage ?? ''}
              markets={value?.global?.languages}
              active={active}
            >
              {children}
            </MarketPickerProvider>
          </FavoritesProvider>
        </GlobalProvider>
      </LazyMotion>
    </SWRProvider>
  );
};
