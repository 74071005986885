import { FavoriteItem } from '@hultafors/shared/types';

import { WibeProduct } from '@hultafors/wibe/types';

export function createProductFromFavorite(input: FavoriteItem): WibeProduct {
  return {
    brandSegments: [],
    image: input.image,
    name: input.name,
    parentCategory: '',
    price: { currency: '', value: { max: 0, min: 0 } },
    productId: input.id,
    sku: input.id,
  };
}
